import React from 'react';
import RecipeDetail from '../components/Recipe/RecipeDetail';

class RecipeDetailPage extends React.PureComponent {
  render() {
    return (
      <RecipeDetail />
    )
  }
}

export default RecipeDetailPage;