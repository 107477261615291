const config = {
  postgraphile: {
    // url: 'http://localhost:5000/graphql',
    url: 'https://query.lissner.io/',
  },
  // credentials: {
  //     awsKey: 'AKIAJ5R7WCGS7F4PYAPA',
  //     awsSecret: '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',
  // },
  // cognito: {
  //     REGION: 'us-west-2',
  //     USER_POOL_ID: 'us-west-2_mucLcMCxG',
  //     IDENTITY_POOL_ID: 'us-west-2:d315185c-a932-43f2-a431-886fa1b99be0',
  //     APP_CLIENT_ID: '5ahk62ikatkc3emromsk5oc7ot',
  // },
  // apiGateway: {
  //     REGION: 'us-west-2',
  //     URL: 'https://ft0v2xi5l7.execute-api.us-west-2.amazonaws.com/prod',
  // },
  // s3: {
  //     BUCKET: 'lissnerlistner.com',
  // },
  // dynamo: {
  //   region: 'us-west-2',
  // },
  // pg: {
  //   user: 'postgres',
  //   pass: 'Xi3xi3jo%23SQL',
  // },
}

module.exports = config;